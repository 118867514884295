import { Button } from '@mui/material';

export default function ButtonFilled({children,backgroundColor,textColor,onClick}){
    return (
        <Button 
            sx={{borderRadius:2.5, textTransform: 'none'}} 
            variant='contained' 
            disableElevation 
            color={backgroundColor} 
            textColor={textColor}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}