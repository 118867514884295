import { Button } from '@mui/material';

export default function ButtonText({children,backgroundColor,textColor, onClick}){
    return (
        <Button 
            sx={{borderRadius:2.5, textTransform: 'none', fontWeight:600}} 
            variant='text' 
            disableElevation 
            color={backgroundColor} 
            textColor={textColor}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}