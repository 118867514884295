import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { getFromAPI, postToAPI } from '../apiRequests';

function EventInscriptionForm({eventId}){
    const [showFinishMessage, setShowFinishMessage] = useState(false);
    const sexOptions = [
        {
          value: 'mujer',
          label: 'Mujer',
        },
        {
          value: 'hombre',
          label: 'Hombre',
        }
    ];
    const defaultEnrrollmentForm = {
        firstName: '',
        lastName: '',
        email:'',
        sex:''
    }
    const [enrollmentForm, setEnrollmentForm] = useState(defaultEnrrollmentForm);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setEnrollmentForm((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
    };
    let finishMessage = (<Typography variant='h4' sx={{marginTop:4}}>Inscripción exitosa!</Typography>);;
    const handleSubmit = async (evnt) => {
        evnt.preventDefault();
        let baseUrl = 'https://httpsflaskexample-xloiag4dva-uc.a.run.app';
        console.log(enrollmentForm);
        let postResponse = await postToAPI(`${baseUrl}/events/${eventId}/participants`,enrollmentForm);
        if(postResponse.status == 201){
            console.log('Inscripción exitosa!');
        }
        else{
            finishMessage = (<Typography variant='h3'>Ocurrio un error, intente mas tarde.</Typography>);
        }
        setShowFinishMessage(true);
    };
    const sxInput = {marginBottom:2}
    let formComponent = (<Box component="form" sx={{fontSize:10}} onSubmit={handleSubmit}>
    <Typography variant="h6" sx={{marginBottom:2}}>Formulario de inscripción</Typography>
    <TextField fullWidth
        sx={sxInput}
        id="firstName"
        label="Nombre(s)"
        name='firstName'
        inputProps={{style: {fontSize: 15}}}
        InputLabelProps={{style: {fontSize: 15}}}
        value={enrollmentForm.firstName}
        onChange={handleChange}
        required
    />
    <TextField fullWidth
        sx={sxInput}
        id="lastName"
        label="Apellido(s)"
        name='lastName'
        value={enrollmentForm.lastName}
        onChange={handleChange}
        required
        inputProps={{style: {fontSize: 15}}}
        InputLabelProps={{style: {fontSize: 15}}}
    />
    <TextField fullWidth
        sx={sxInput}
        id="email"
        label="Email"
        type='email'
        name='email'
        value={enrollmentForm.email}
        onChange={handleChange}
        required
        inputProps={{style: {fontSize: 15}}}
        InputLabelProps={{style: {fontSize: 15}}}
    />
    <TextField fullWidth
        sx={sxInput}
        select
        id="sex"
        label="Sexo"
        name='sex'
        value={enrollmentForm.sex}
        required
        onChange={handleChange}
        inputProps={{style: {fontSize: 15}}}
        InputLabelProps={{style: {fontSize: 15}}}
    >
    {sexOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
        {option.label}
        </MenuItem>
    ))}
    </TextField>
    <Button type="submit">Enviar</Button>
</Box>);
    return (<Box>{showFinishMessage?finishMessage:formComponent}</Box>); 
}

function EventDetail(){
    const location = useLocation();
    const eventId = location.pathname.split('/')[2];
    const baseUrl = 'https://httpsflaskexample-xloiag4dva-uc.a.run.app';
    const [event,setEvent] = useState({title:'Título',description:'Descripción'})
    
    const fetchEvent = async () => {
        let responseGetEvent = await getFromAPI(`${baseUrl}/events/${eventId}`);
        if(responseGetEvent.status == 200){
            setEvent(responseGetEvent.data);
        }
    };

    useEffect(() => {
        fetchEvent();
    }, []);

    return (<Box sx={{display:'flex', flexDirection: {'xs':'column','sm':'column','md':'row'}, margin:5,padding:2, border:1.8,borderRadius:4,borderColor:'grey.main'}}>
    <Box sx={{width:{'xs':1,'sm':1,'md':0.5}}}>
        <Box 
            component="img"
            sx={{width:1, borderRadius: 4}}
            alt="The house from the offer."
            // src={require('../static/images/FacebookPost.png')}>
            src={`${baseUrl}/events/${eventId}-poster.png`}>
        </Box>
    </Box>
    <Box sx={{margin:5,width:{'xs':0.9,'sm':0.9,'md':0.5}}}>
        <Typography variant="h4" sx={{marginBottom:2}}>{event.title}</Typography>
        <Typography variant="mainSmall" sx={{marginBottom:2}}>{event.description}</Typography>
        <EventInscriptionForm eventId={eventId}></EventInscriptionForm>
    </Box>
    </Box>);
}

export {EventDetail}