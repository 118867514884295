// import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App'
import { createTheme, ThemeProvider, styled, responsiveFontSizes } from '@mui/material/styles'
  
let theme = createTheme({
typography: {
    fontFamily: `Urbanist`,
    fontWeightRegular: 500,
    fontSize: 16,
    h1 : {
        fontFamily: 'Urbanist',
        fontWeight: 600,
        fontSize:120,
    },
    h2 : {
        fontWeight : 700,
        fontSize : 80
    },
    h3 : {
        fontWeight : 600,
        fontSize : 50,
    },
    h4 : {
        fontFamily : `Urbanist`,
        fontWeight : 600,
        fontSize : 32
    },
    h5 : {
        fontFamily : `Urbanist`,
        fontWeight : 600,
        fontSize : 29
    },
    h6 : {
        fontFamily : `Urbanist`,
        fontWeight : 600,
        fontSize : 25
    },
    main : {
        fontFamily : `Inter`,
        fontWeight : 400,
        fontSize : 24,
    },
    body1 : {
        fontFamily : `Inter`,
        fontWeight : 400,
        fontSize : 22,
    },
    body2 : {
        fontFamily : `Inter`,
        fontWeight : 400,
        fontSize : 18,
    },
    subtitle1 : {
        fontFamily : `Urbanist`,
        fontWeight : 600,
        fontSize : 16,
    },
    mainSmall : {
        fontFamily : `Inter`,
        fontWeight : 400,
        fontSize : 16,
    }
},
palette: {
    // primary: {
    //   // light: will be calculated from palette.primary.main,
    //   main: '#24292f', //orange
    //   // dark: will be calculated from palette.primary.main,
    //   contrastText: '#FFFFFF'
    // },
    // secondary: {
    //   light: '#0066ff',
    //   main: '#FFFFFF',
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#0f141a',
    // },

    action: {
      main: '#0167b3',
      contrastText: '#ffffff',
    },
    grey : {
        main: '#d0d7de'
    }
}
});

theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<ThemeProvider theme={theme}><App/></ThemeProvider>)