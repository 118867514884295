import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonFilled from './ButtonFilled';
import ButtonActionText from './ButtonActionText';

export default function EventCard({_id,imgSrc,title,description,datetime_start}) {
  datetime_start = new Date(datetime_start);
  const navigate = useNavigate();
  const handleInscription = (evnt) => {
    evnt.preventDefault();
    console.log('Inscribirme');
    navigate(`/events/${_id['$oid']}`,{'state':title});
  }
  const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
  return (
    <Card sx={{  borderRadius: '30px', height:500,maxWidth:500, mx:'10px', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
      <CardMedia component="img" sx={{height:285,width:500}} image={imgSrc}/>
      <CardContent sx={{ px:4, display:'flex', flexDirection:'column',  justifyContent: 'flex-start'}}>
        <Typography  variant="subtitle1" color="text.secondary">
          {datetime_start.toLocaleDateString('es-BO',options)}
        </Typography>
        <Typography gutterBottom variant="h6" component="div" color="#3662C1">
          {title}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          {description}
        </Typography> */}
      </CardContent>
      <CardActions sx={{px:4, display: "flex", justifyContent:'flex-end'}}>
        <ButtonActionText>Ver más</ButtonActionText>
        <ButtonFilled onClick={handleInscription}>Inscribirme</ButtonFilled>
      </CardActions>
    </Card>
  );
}