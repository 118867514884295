import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Section from './Section';

export default function DescriptionSection({title='',description='',imgSrc=null, titleColor='#000000', imgSide='right', background='#f5f4f4'}){
    let img = null, limg = null, rimg = null
    let boxSx = {
        width:{xs:0.75,sm:0.5,md:0.3},
        display:'flex', 
        flexDirection:'column',
        alignItems:'center',
        borderRadius: '30px',
        background:'#ffffff',
        padding:{xs:4,sm:8,md:12}
    } 
    if (imgSrc != null ){
        img = (<Box
            component="img"
            sx={{width:{xs:0.3,sm:0.3,md:0.5},mt:{xs:3,sm:3},maxWidth:400, px:10, pb: 5}}
            alt=""
            src={imgSrc}
        />)
    }
    rimg = img
    if (imgSide!='right'){
        limg = img
        rimg = null
    }
    return (<Section background={background}>
        {limg}
        <Box sx={boxSx}>
            <Typography variant='h3' sx={{marginBottom:1.5, color:titleColor, letterSpacing:'0.03em'}}>
            {title}
            </Typography>
            <Typography variant='body1' sx={{marginBottom:1, color:'#4b515a'}}>
            {description}
            </Typography>
        </Box>
        {rimg}
    </Section>)
}