import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom'
import axios from "axios";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import Footer from '../components/Footer'
import Section from '../components/Section'
import DescriptionSection from '../components/DescriptionSection'
import EventCard from '../components/EventCard'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Carousel from '../components/Carousel';

import {getFromAPI} from '../apiRequests'

function FirstMessage(){
    let sectionOneSx = {
        background:'linear-gradient(180deg, rgba(149, 94, 182, 0) 63.59%, #FFFFFF 100%), radial-gradient(88.34% 357.28% at 12.81% 7.84%, #007FCC 17.08%, #F5009B 91.78%);',
        minHeight:{xs:1000,sm:1150,md:1000},
        border:0,
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
        color:'#ffffff'
    }
    return (<Box sx={sectionOneSx}>
        <AdasecLogo></AdasecLogo>
        <Box sx={{paddingTop:{xs:5,md:15}, width:0.75}}>
            <Typography variant='h1'>
                Desarrollo Integral
            </Typography>
            <Typography variant='h1' sx={{marginBottom:4}}>
                del Ser Humano
            </Typography>
            <Typography variant='main' sx={{width:{xs:1,md:0.70}}}>
            Promovemos el desarrollo integral del ser humano, mediante la <b>educación en valores</b> junto el <b>desarrollo sostenible</b> a través de acciones de <b>ayuda social, ecológica y cultural</b>.
            </Typography>
        </Box>

        <Box sx={{mt:2,color:'#ffffff',alignSelf:'flex-end',pr:15}}>
            <IconButton aria-label="www.instagram.com/adasec.bolivia" onClick={() => window.open('https://www.instagram.com/adasec.bolivia')}>
            <InstagramIcon sx={{fontSize:40, color:'#ffffff'}}/>
            </IconButton>
            <IconButton aria-label="www.facebook.com/adasec.bolivia/" onClick={() => window.open('https://facebook.com/adasec.bolivia/')}>
            <FacebookRoundedIcon sx={{fontSize:40, color:'#ffffff'}}/>
            </IconButton>
            <IconButton aria-label="www.youtube.com" onClick={() => window.open('https://www.youtube.com/channel/UCWokfAQ0ps9usMMSJTu2p2A')}>
            <YouTubeIcon sx={{fontSize:40, color:'#ffffff'}}/>
            </IconButton>
        </Box>
    </Box>)
}

function AdasecLogo(){
    return (
        <Box sx={{display:'flex',alignItems:'center',pt:5,pl:{xs:0,md:10},alignSelf:{xs:'center',md:'flex-start'}}}>
            <Box 
                component="img"
                sx={{width:50,height:50,pr:1}}
                alt="The house from the offer."
                src={require('../static/images/AdasecBoliviaLogoWhite5x.png')}>
            </Box>
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',color:'#ffffff'}}>
                <Typography variant='h6' sx={{margin:0,pt:2, letterSpacing:'0.05em',lineHeight:0.3}}>
                    ADASEC
                </Typography>
                <Typography variant='h6' sx={{margin:0,padding:0,letterSpacing:'0.02em'}}>
                    BOLIVIA
                </Typography>
            </Box>
        </Box>)
}


function MainPage(){
    let descriptionSections = [
        {
            'title':'Área de Ayuda Social',
            'description':'El Área de Ayuda Social aborda las necesidades del ser humano, procurando que  cada ser humano tenga unas condiciones óptimas de vida y realice los cambios positivos de su propio destino, con programas de ayuda familiar, sanitaria, educacional, con acciones de comedores infantiles, cursos de alfabetización para aquellas personas que no pueden pagarse  su aprendizaje, asistencia médica y familiar.',
            'imgSrc':require('../static/images/Social_SmilePattern_Blue.png'),
            'titleColor':'#3662C1',
            'background':'#009eff'
        },
        {
            'title':'Área de Ecología',
            'description':'El Área de Ecología  enseña la relación del ser humano con el entorno, el respeto a la naturaleza, la no contaminación del medio ambiente, el cuidado de las aguas, mares y ríos. En el aspecto más elevado, educa para una mayor conciencia ecológica y medioambiental, la protección de la atmósfera y el aire.',
            'imgSrc':require('../static/images/Ecologica_FlowerPattern_Yellow.png'),
            'titleColor':'#dca22e',
            'imgSide':'left',
            //'background':'#ffdf4b'
        },
        {
            'title':'Área de Cultura',
            'description':'El Área de Cultura apoya la educación primaria, secundaria, universitaria, a través de la educación en valores humanos y espirituales y  la sanación de la personalidad, para que la persona pueda lograr  un mejor aprovechamiento e integración en sí misma. Mediante actividades de formación y sensibilización en valores humanos, con el propósito de aportar a la sociedad una educación de la conciencia, impartiendo conferencias, cursos, talleres, seminarios y aulas abiertas.',
            'imgSrc':require('../static/images/Mesa de trabajo 1@2x.png'),
            'titleColor':'#f5019b',
            'background':'#aa337e'
        }
    ]
    const [events,setEvents] = useState([]);
    const baseUrl = 'https://httpsflaskexample-xloiag4dva-uc.a.run.app';
    const fetchEvents = async () => {
        console.log('fetchEvents')
        let responseGetEvents = await getFromAPI(`${baseUrl}/events`);
        console.log(responseGetEvents)
        if(responseGetEvents.status == 200){
            let eventsData = responseGetEvents.data['events'];
            eventsData.map((e)=>{e.imgSrc = `${baseUrl}/events/${e['_id']['$oid']}-card.png`});
            console.log(eventsData);
            setEvents(eventsData);
        }
    };

    useEffect(() => {
        console.log('useEffect');
        fetchEvents();
    }, []);

    let footerData = [
        {'Email':['adasec.bo@gmail.com']},
        {'Teléfonos':['+591 44521372']},
        {'Dirección':['Calle 25 de Mayo','Cochabamba, Bolivia']}
    ]
    return (<>
    <FirstMessage/>
    <Section background='linear-gradient(180deg, #FFFFFF 0%, #F5F4F4 100%);'>
        <Box sx={{width:{xs:0.7,sm:0.7,md:0.52},display:'flex', flexDirection:'column',alignItems:'center',marginRight:{md:10}}}>
            <Typography variant='h2' sx={{marginBottom:1, color:'#3662C1', letterSpacing:'0.07em'}}>
            ADASEC BOLIVIA
            </Typography>
            <Typography variant='h4' sx={{marginBottom:4, color:'#3662C1'}}>
            Asociación De Ayuda Social Ecológica y Cultural
            </Typography>
            <Typography variant='body1' sx={{marginBottom:1, color:'#4b515a'}}>
            ¿Quiénes somos? Somos filial en Bolivia de la ONG con presencia en más de 50 países FIADASEC, miembro de la ONU. FIADASEC es la Federación Internacional de Asociaciones de Ayuda Social, Ecológica y Cultural que agrupa a asociaciones de diversos países a escala mundial, cuyo objetivo es elevar el nivel de conciencia de las personas desde  una educación social, ecológica y cultural.
            </Typography>
        </Box>
        <Box
        component="img"
        sx={{width:{xs:0.7,sm:0.7,md:0.18},mt:{xs:3,sm:3},maxWidth:300}}
        alt="The house from the offer."
        src={require('../static/images/shapesCompositionX4.png')}
      />
    </Section>
    <Section>
        <Box>
            {/* <ArrowBackIosNewIcon/> */}
            <Typography variant='h4' sx={{marginBottom:4,marginLeft:1, color:'#3662C1'}}>
                Próximos Eventos
            </Typography>
            <Box sx={{display:'flex',flexDirection:'row'}}>
                {events.map((ev)=>(<EventCard {...ev}/>))}
            </Box>
            {/* <ArrowForwardIosIcon/> */}
        </Box>
    </Section>
    {descriptionSections.map((section)=>{
        return (<DescriptionSection title={section.title} description={section.description} imgSrc={section.imgSrc} titleColor={section.titleColor} imgSide={section.imgSide} background={section.background}/>)
    })}
    <Footer sections={footerData} />
    </>)
    //to do, padding last section, refactor sx of section
}
export {MainPage}