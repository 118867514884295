import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom'
import { MainPage } from './views/MainPage'
import { EventDetail } from './views/EventDetail'
function App() {
    return (
            <Router>
                <Routes>
                    <Route exact path="/" element={<MainPage/>}></Route>
                    <Route path="/events/:id" element={<EventDetail/>}></Route>
                </Routes>
            </Router>
    )
}

export default App