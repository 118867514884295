import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function Section({children,background='#f5f4f4'}){
    let sectionOneSx = {
        border:0,
        background:background,
        display:'flex',
        flexDirection:{xs:'column',sm:'column',md:'row'},
        alignItems:{xs:'center',sm:'center',md:'center'},
        justifyContent:{sm:'flex-start',xs:'flex-start',md:'center'},
        py:{xs:4,sm:8,md:10},
        width:1
    }
    return (
            <Box sx={sectionOneSx}>
                {children}
            </Box>);
}