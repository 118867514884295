import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer({sections=[],socialMedia=[]}){
    let sectionOneSx = {
        background:'#3662C1',
        minHeight:500,
        border:0,
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        justifyContent:'center'
    }
    return (<Box sx={sectionOneSx}>
        <Box sx={{py:10, width:0.75, display:'flex',flexDirection:{xs:'column',sm:'column',md:'row'}, justifyContent:'flex-start', alignItems:'top'}}>
            <Box sx={{marginRight:15}}>
            {
                sections.map((s)=>{
                    let prop = Object.keys(s);
                    console.log(prop[0]);
                    console.log(s[prop[0]]);
                    return (<>
                        <Typography variant='subtitle1' sx={{marginBottom:1, color : 'rgba(255, 255, 255, 0.6);'}}>
                            {prop[0]}
                        </Typography>
                        {
                            s[prop[0]].map((d,idx)=>{
                                let lastIdx = s[prop[0]].length - 1;
                                let mB = idx == lastIdx ? 4 : 1;
                                return (<Typography variant='h4' sx={{color:'#ffffff',marginBottom:mB}}>
                                    {d}
                                </Typography>)
                            })
                        }
                    </>)
                })
            }
            </Box>
            <Box sx={{display:'inline'}}>
                <Typography variant='subtitle1' sx={{marginBottom:1, color : 'rgba(255, 255, 255, 0.6);'}}>
                    Redes sociales
                </Typography>
                <Box sx={{color:'#ffffff',alignSelf:'flex-end',pr:15}}>
                    <IconButton aria-label="www.instagram.com/adasec.bolivia" onClick={() => window.open('https://www.instagram.com/adasec.bolivia')}>
                    <InstagramIcon sx={{fontSize:32, color:'#ffffff'}}/>
                    </IconButton>
                    <IconButton aria-label="www.facebook.com/adasec.bolivia/" onClick={() => window.open('https://facebook.com/adasec.bolivia/')}>
                    <FacebookRoundedIcon sx={{fontSize:32, color:'#ffffff'}}/>
                    </IconButton>
                    <IconButton aria-label="www.youtube.com" onClick={() => window.open('https://www.youtube.com/channel/UCWokfAQ0ps9usMMSJTu2p2A')}>
                    <YouTubeIcon sx={{fontSize:32, color:'#ffffff'}}/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    </Box>)
}